body{
	position: relative;
}

h1, h2, h3, h4, h5, h6{
    font-family: "Lato", serif !important;
}

p, span, a{
    font-family: "Raleway", sans-serif !important;
}

a{
    text-decoration: none;
}
.button{
    padding: 12px;
    border: 2px solid;
    border-radius: 3px;
    text-transform: uppercase;
    -webkit-transition: all .3s;
    transition: all .3s;
    font-size: 16px;
}

/* Scrollbar */

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #11ABB0;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #159296;
    }




@media (max-width: 576px){

	#header{
        background-size: initial !important;
        background-position: top !important;
	}

	#header h1{
        font-size: 40px !important;
    }

    #header h4{
        font-size: 16px !important;
    }

	#portfolio h2{font-size: 25px !important;}
}




/* H E A D E R */


#header{
	min-height: 100vh;
	background-image: url(../images/background.jpeg);
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;

}


.menu-overlay{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.09);
    z-index: 1;
}

#overlay{
    background-color: #22A39F;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 0.75;
/*filter: alpha(opacity=75);*/
    z-index: 0;
    height: 100vh   ;
}
.menu{
    position: fixed;
    top: 20px;
    left: 10px;
    z-index: 1;
}


.nav-content{
	position: relative;
    display: none;
    padding: 0;
    background: rgba(0, 0, 0, .8);
}

.nav-content > li{
    list-style: none;
    padding: 5px 20px;
}

.nav-content > li > a{
    display: block;
    text-decoration: none;
    line-height: 32px;
    color: #F5F5F5;
    -webkit-transition: .3s;
    transition: .3s;
    letter-spacing: 1px;
    font-size: 14px;
    padding: 0 !important;
}

.nav-content > li > a:hover, .active{
    color: #11ABB0 !important;
}

.toggler{
    padding: 10px 15px;
    color: #FFFFFF;
    background-color: #0F0F0F;
    display: block;
    cursor: pointer;
    width: min-content;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: -ms-min-content;
    width: -o-min-content;
}

.bar1, .bar2, .bar3 {
    width: 25px;
    height: 3px;
    background-color: #F5F5F5;
    margin: 6px 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-5px, 5px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}

.nav-content #dropdown-menu{
	display: none;
	background: rgba(0, 0, 0, .8);
	position: absolute;
	top: 45%;
	left: 100%;
	border-left: 1px solid rgba(0,0,0,.3);
	padding: 10px 0;
}

.nav-content #dropdown-menu ul{
	list-style-type: none;
	padding: 0;
}

.nav-content #dropdown-menu ul li a{
	color: #F5F5F5;
}

#dropdown{
	position: relative;
}

#header .dropdown-item:hover, #header .dropdown-item.active{
	color: #11ABB0;
	background: transparent !important;
}


#header > .row{
	min-height: 100vh;
	width: 100vw;
}

#header h1{
	font-size: 80px;
	color: #F5F5F5;
    z-index: 1;
    position: relative;
}

#header hr{
	width: 200px;
	border-color: #F5F5F5;
    z-index: 1;
    position: relative;
}

#header h4{
	font-size: 20px;
	font-weight: normal;
	color: #F5F5F5;
    z-index: 4;
    position: relative;
}



/* A B O U T */




#about{
	padding-top: 80px;
	padding-bottom: 80px;
	background: #2B2B2B;
    z-index: 1;
}

#about h2{
	margin-bottom: 30px;
	color: #F5F5F5;
}

#about p{
	color: #999;
}

#about img{
	width: 180px;
}

@media (max-width: 992px){
	.picture{display: none;}
}



/* C V */



#cv{
	padding-top: 80px;
	padding-bottom: 80px;
	background: #F7F7F7;

}

#cv  hr{
    height: 1px;
    border: none;
    background: #DDD;
    margin: 50px auto;
}

#cv .row h3{
	border-bottom: 3px solid #11ABB0;
	font-size: 24px;
	padding-bottom: 5px;
    width: min-content;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: -ms-min-content;
    width: -o-min-content;
	margin-bottom: 50px;
}

#cv .row{
	margin-bottom: 80px;
}

.skills + .skills{
    margin-top: 60px;

}

.skills > h4{
    color: #11ABB0;
    margin-bottom: 40px;
}

#cv .skill-item + .skill-item{
	margin-top: 30px;
}

#cv .skill-item > h4{
	font-size: 16px;
	color: #222;
}

#cv .skill-item > .progress{
	height: 40px;
	background: #DDD;
}

#cv .skill-item .progress-bar{
	background: #222;
}

.exp + .exp{
    margin-top: 60px;

}

.exp > h4{
    color: #11ABB0;
    margin-bottom: 40px;
}

#cv .box + .box{
    margin-top: 50px;
}

#cv .box h4{
	font-size: 20px;
	color: #222;
}

#cv .box h6{
	color: #11ABB0;
}

#cv .box p{
	color: #888;
}




/* P O R T F O L I O */


#portfolio{
	padding: 80px 0;
    background: #EBEEEE;
}


#portfolio h2{
    padding: 0;
    margin: auto;
    margin-bottom: 50px;
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    color: #666;
}

#portfolio img{
	max-width: 100%;
}

#portfolio .row > div{
	margin-bottom: 1.5rem;
    position: relative;
}



#portfolio .row div .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: calc(100% - 30px);
    transform: scale(0);
    margin: auto;
    opacity: 0;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    background: rgba(0, 0, 0, .55);
    border-radius: 20%;
}

#portfolio .row div:hover .overlay {
    opacity: 1;
    transform: scale(1);
    height: 100%;
    border-radius: 0;
}

#portfolio .row div .overlay h3{
    color: #f7f7f7;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -200%);
            transform: translate(-50%, -200%);
    -ms-transform: translate(-50%, -200%);
    letter-spacing: .05em;
}

.overlay h3.project-title{
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
}

.project-work-image{
    height: 380px;
}

.portfolio-image{
    height: 320px;
}

  /*!* TESTIMONIALS *!*/

/*.testimonials_area{*/
    /*background: #f9f9ff;*/
/*}*/
/*.main_title{*/
    /*text-align: center;*/
    /*margin-bottom: 75px;*/
/*}*/

/*section div.container{*/
    /*max-width: 1170px;*/
    /*width: 100%;*/
    /*padding-right: 15px;*/
    /*padding-left: 15px;*/
    /*margin-right: auto;*/
    /*margin-left: auto;*/
    /*max-height: 100vh;*/
/*}*/

/*  C O N T A C T */



#contact{
    background: #191919;
    color: #F3F3F3;
    text-align: center;
    padding: 80px 0 100px 0;
}

#contact div.left > span{
    font-size: 80px;
    color: #11ABB0;
    line-height: 0;
}

#contact h4{
    display: inline-block;
    padding-bottom: 5px;
    border-bottom: 2px solid #11ABB0;
    text-align: left;
    text-transform: uppercase;
    color: #F5F5F5;
    font-size: 25px;
}

#form{
    width: 100%;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

#form > div {
    position: relative;
    margin-top: 30px;
}

.firstname, .lastname, .email, .tel{
    width: 45%;
}

.subject, .msg{
    width: 100%;
}

.btn-div{
	min-width: 30%;
    max-width: 50%;
    padding: 0;
}

#contact input, textarea{
    text-align: left;
    background: transparent !important;
    border-width: 0 0 1px 0 !important;
    border-color: #DDD;
    display: block;
    width: 100%;
    padding: 0 12px 6px 0;
    font-size: 14px;
    color: #F3F3F3;
    margin-top: 20px !important;
    height: 35px;
    font-family: "Raleway", sans-serif !important;
}

#contact input:focus, #contact textarea:focus{
    outline: none;
}

.focus-border{
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: #11ABB0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:focus ~ .focus-border, textarea:focus ~ .focus-border{
    width: 100%;
}

#contact textarea{
    resize: vertical;
    overflow-y: auto;
    min-height: 100px;
    max-height: 200px;
}

#contact .btn-send{
    width: 100%;
    cursor: pointer;
    margin-top: 20px;
    background: transparent;
    border-color: #F3F3F3;
    color: #F3F3F3;
    text-transform: uppercase;
    letter-spacing: 1px;
}

#contact .btn-send:hover, #contact .btn-send:focus{
    color: #333;
    background:#F3F3F3;
}

#contact .btn-send:focus{
    outline: none;
}

#contact .btn-send .fa{
    margin-right: 10px;
}

.label{
    -webkit-transition: .4s ease all;
    transition: .4s ease all;
    position: absolute;
    top: 25px !important;
    color: #AAA;
    text-align: left;
    font-size: 16px;
    display: block;
    cursor: text;
    font-family: "Raleway", sans-serif;
}

input:focus ~ .label, textarea:focus ~ .label[for=message], input.has-value ~ .label, textarea.has-value ~ .label[for=message]{
    font-size: 12px !important;
    top: 0px !important;
    color: #11ABB0 !important;
}


.input-info{
    width: 100%;
    color: #11ABB0;
    text-align: left;
}

@media (max-width: 768px){

    #contact .left{
        display: none;
    }

    #contact .row{
    	margin: auto;
    	width: 90%;
    }

    #form{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .firstname, .lastname, .email, .tel, .btn-div{
        width: 100%;
    }

}






/*  F O O T E R  */

footer{
    background: #0F0F0F;
    position: relative;
    padding: 80px;
}

.footer-content{
    text-align: center;
    max-width: 85%;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.footer-content p{
    color: #565656;
}

.footer-content a{
    display: inline-block;
    text-decoration: none;
    font-size: 20px;
    color: #565656;
    -webkit-transition: .3s;
    transition: .3s;
}

.footer-content a:hover{
    color: #FFF;
}

.footer-content a + a{
    margin-left: 30px;
}

footer h4{
    color: #DDD;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;
    font-size: 18px;
}


@media (max-width: 768px){
	footer{
        padding: 80px 0;
    }

    .footer-content{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .footer-content > div{
        width: 100% !important;
        margin-bottom: 45px;
    }

    .footer-content h4{
        margin-bottom: 20px;
    }

    .footer-content a + a{
        margin-left: 18px;
    }
}